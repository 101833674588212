import "./App.css";
import "antd/dist/antd.css";
import Axios from "axios";
import React, { useState } from "react";
import {
  Form,
  Input,
  Button,
  Checkbox,
  Radio,
  Select,
  InputNumber,
} from "antd";
import { Row, Col, notification, ConfigProvider, DatePicker } from "antd";

import { useTranslation } from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";
import moment from "moment";
function App() {
  const { Option } = Select;
  const { TextArea } = Input;
  const { t, i18n } = useTranslation();
  const recaptchaRef = React.createRef();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [direction, setDirection] = useState("rtl");
  const [hasVehicleState, setHasVehicleState] = useState("");
  console.log(form);

  const showNotification = (
    type = "success",
    message = "",
    description = ""
  ) => {
    notification[type]({
      message,
      description,
    });
  };
  const dateFormat = "YYYY/MM/DD";
  const onVehicleChange = (value) => {
    console.log();
    setHasVehicleState(value.target.value);
  };

  const ages = [
    2006, 2005, 2004, 2003, 2002, 2001, 2000, 1999, 1998, 1997, 1996, 1995,
    1994, 1993, 1992, 1991, 1990, 1989, 1988, 1987, 1986,

    1985, 1984, 1983, 1982, 1981, 1980, 1979, 1978, 1977, 1976, 1975, 1974,
    1973, 1972, 1971, 1970,
  ];
  const onFinish = (values) => {
    // const date = moment(values.dateofbirth).format("MM/DD/YYYY");
    // values.dateofbirth = date;
    recaptchaRef.current.execute();
    console.log("values", values);
    setLoading(true);
    // return;
    Axios.post(
      "https://production.backendapi.lezzoodevs.com/service/joindriver",
      values
    )
      .then((res) => {
        setLoading(false);
        console.log("done", res);
        showNotification("success", "Submitted successfully");
        form.resetFields();
      })
      .catch((error) => {
        showNotification("warning", "Something went wrong");
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    showNotification("warning", "Please fill required fields!");
    setLoading(false);
  };

  const handleChangeLanguage = (lang) => {
    console.log("clicked");
    i18n.changeLanguage(lang);
  };
  const onChange = () => {};
  return (
    <Row gutter={[10, 10]} style={{ justifyContent: "center" }}>
      <div
        className="flex formfix"
        style={{
          justifyContent: "center",
          paddingTop: 50,
        }}
      >
        <div className="form">
          <Col className="gutter-row" span={24}>
            <div
              className="flex"
              style={{
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                alt={"Lezzoo"}
                style={{ height: 40, marginRight: 20, marginBottom: 20 }}
                src={"https://lezzoo.com/assets/images/logo-icon.svg"}
              />
              <h3>{t("header.1")}</h3>
            </div>{" "}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 15,
              }}
            >
              {" "}
              <p style={{ marginLeft: 20, fontSize: 12, fontWeight: 600 }}>
                {t("form.chooselang")}
              </p>
            </div>
          </Col>

          <ConfigProvider direction={direction}>
            <Form
              form={form}
              name="basic"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                sitekey="6LdN49cbAAAAAHRRt7rdPf_kSUY39GNSKLPVrlcm"
                onChange={onChange}
              />
              <div>
                <Col
                  span={12}
                  offset={6}
                  justify="space-around"
                  style={{ padding: " 25px 0px" }}
                >
                  <div
                    className="flex"
                    style={{ justifyContent: "space-around" }}
                  >
                    <Button
                      size="small"
                      onClick={() => {
                        setDirection("ltr");
                        handleChangeLanguage("en");
                      }}
                    >
                      English
                    </Button>
                    <Button
                      size="small"
                      onClick={() => {
                        setDirection("rtl");
                        handleChangeLanguage("ar");
                      }}
                    >
                      العربية
                    </Button>
                    <Button
                      size="small"
                      onClick={() => {
                        setDirection("rtl");
                        handleChangeLanguage("krSorani");
                      }}
                    >
                      کوردی سۆرانی
                    </Button>
                    <Button
                      size="small"
                      onClick={() => {
                        setDirection("rtl");
                        handleChangeLanguage("krBadini");
                      }}
                    >
                      کوردی بادینی
                    </Button>
                  </div>
                </Col>
                <div
                  style={
                    {
                      // display: "flex",
                      // justifyContent: "center",
                      // alignItems: "center",
                      // fontSize: 16,
                    }
                  }
                >
                  <div
                    style={{
                      marginTop: 10,

                      fontSize: 12,
                      marginLeft: 10,
                      marginRight: 10,
                    }}
                  >
                    <p>
                      {" "}
                      <b> {t("text.1")}</b>
                    </p>
                    <p>{t("text.2")}</p>
                    <p>{t("text.3")}</p>
                    <ul>
                      <li>{t("text.4")}</li>
                      <li>{t("text.5")}</li>
                      <li> {t("text.6")}</li>
                      <li> {t("text.7")}</li>
                    </ul>
                    {/* <p>{t("text.8")}</p> */}
                  </div>
                </div>

                {/* <Col>
                  <div
                    style={{ marginTop: 10, textAlign: "left", fontSize: 11 }}
                  >
                    <p> Lezzoo is hiring a large number of Delivery Drivers</p>
                    <p>
                      If the driver is lacking a valid driving license or
                      Vehicle, our company facilitates the procedures for the
                      driver to obtain a legal driver's license, and the driver
                      can use one of the company's motorcycles or buy them from
                      the company with monthly payments
                    </p>
                    <p>Job requirements</p>
                    <ul>
                      <li>Valid driving license</li>
                      <li>Motorcycle/car</li>
                      <li> Languages: Kurdish or Arabic</li>
                      <li> Languages: Kurdish or Arabic</li>
                      <li> Age: 18-45 years</li>
                    </ul>
                    <p>
                      Your Monthly salary will be based on the working hours you
                      choose to work
                    </p>
                  </div>
                </Col> */}

                <div className="flex">
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <div className="layout">
                      <p>
                        <b>{t("form.fullname")}</b>
                      </p>
                      <Form.Item
                        name="fullname"
                        rules={[
                          {
                            required: true,
                            message: `${t("form.fullnameMessage")}`,
                          },
                        ]}
                        className="dropdown"
                      >
                        <Input />
                      </Form.Item>{" "}
                    </div>{" "}
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <div className="layout">
                      <p>
                        {" "}
                        <b>{t("form.phoneNumber")}</b>
                      </p>

                      <Form.Item
                        className="dropdown"
                        name="phonenumber"
                        rules={[
                          {
                            required: true,
                            message: `${t("form.phoneNumberMessage")}`,
                          },
                        ]}
                      >
                        <Input maxLength={11} placeholder="07xx xxxx xxx" />
                      </Form.Item>
                    </div>
                  </Col>
                </div>
              </div>
              <div className="flex">
                {" "}
                <div
                  className="flex extra"
                  // style={{ justifyContent: "space-between" }}
                >
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <div className="layout">
                      <p>
                        <b>{t("form.city")}</b>
                      </p>
                      <Form.Item
                        name="city"
                        className="dropdown"
                        rules={[
                          {
                            required: true,
                            message: `${t("form.citymessage")}`,
                          },
                        ]}
                      >
                        <Select>
                          <Option value="erbil">{t("form.cityErbil")}</Option>
                          <Option value="sulaimani">
                            {t("form.citySulaimani")}
                          </Option>
                          <Option value="dohuk">{t("form.cityDhouk")}</Option>
                          <Option value="baghdad">
                            {t("form.cityBaghdad")}
                          </Option>
                          <Option value="kirkuk">{t("form.cityKirkuk")}</Option>
                          <Option value="Koye">{t("form.cityKoye")}</Option>
                          <Option value="Akre">{t("form.cityAkre")}</Option>
                          <Option value="Soran">{t("form.citySoran")}</Option>
                          <Option value="Shaqlawa">
                            {t("form.cityShaqlawa")}
                          </Option>
                          <Option value="Massif">{t("form.cityMassif")}</Option>
                          <Option value="Zakho">{t("form.cityZakho")}</Option>
                          <Option value="Mosul">{t("form.cityMousl")}</Option>
                          <Option value="Ramadi">{t("form.cityRamadi")}</Option>
                          <Option value="Halabja">
                            {t("form.cityHalabja")}
                          </Option>
                          <Option value="Khanaqin">
                            {t("form.cityKhanaqeen")}
                          </Option>
                        </Select>
                      </Form.Item>
                    </div>
                  </Col>{" "}
                  {/* <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <div className="layout">
                      <p>
                        <b>{t("form.zone")}</b>
                      </p>
                      <Form.Item
                        name="zone"
                        className="dropdown"
                        rules={[
                          {
                            required: true,
                            message: `${t("form.zone")}`,
                          },
                        ]}
                      >
                        <Select>
                          <Option value="city_border">
                            {t("zones.city_border")}
                          </Option>
                          <Option value="city_center">
                            {t("zones.city_center")}
                          </Option>
                        </Select>
                      </Form.Item>
                    </div>
                  </Col>{" "} */}
                </div>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <div className="flex">
                    <div className="layout">
                      <p>
                        <b>{t("form.nationality")}</b>
                      </p>
                      <Form.Item
                        className="dropdown"
                        name="nationality"
                        rules={[
                          {
                            required: true,
                            message: `${t("form.nationalityMessage")}`,
                          },
                        ]}
                      >
                        <Radio.Group>
                          <Radio value="kurdish">
                            {t("form.nationalitykurdish")}
                          </Radio>
                          <Radio value="arabic">
                            {t("form.nationalityArabic")}
                          </Radio>
                          <Radio value="syrian">
                            {t("form.nationalitySyrian")}
                          </Radio>
                          <Radio value="other">
                            {t("form.nationalityOther")}
                          </Radio>
                        </Radio.Group>
                      </Form.Item>
                    </div>
                    {/* <Col xs={24} sm={24} md={12} lg={4} xl={4}> */}
                    <div className="layout" style={{ width: "150px" }}>
                      <p>
                        <b>{t("form.age")}</b>
                      </p>
                      <Form.Item
                        name="dateofbirth"
                        className="dropdown"
                        rules={[
                          {
                            required: true,
                            message: `${t("form.ageMessage")}`,
                          },
                        ]}
                      >
                        {/* <DatePicker format={dateFormat} /> */}
                        <Select>
                          {ages.map((age) => {
                            return <Option value={age}>{age}</Option>;
                          })}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                  {/* </Col> */}
                </Col>
              </div>

              <div className="flex">
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <div className="layout">
                    <p>
                      <b>{t("form.languageSkills")}</b>
                    </p>
                    <div className="flex">
                      <Form.Item
                        valuePropName="checked"
                        name="doesHeKnowArabic"
                        // rules={[
                        //   {
                        //     required: true,
                        //     message: "Please type probelm description!",
                        //   },
                        // ]}
                        valuePropName="checked"
                      >
                        <Checkbox>{t("form.languageSkillsArabic")}</Checkbox>
                      </Form.Item>

                      <Form.Item
                        valuePropName="checked"
                        className="dropdown"
                        valuePropName="checked"
                        name="doesHeKnowKurdish"
                        // rules={[
                        //   {
                        //     required: true,
                        //     message: `${t("form.languageSkillsMessage")}`,
                        //   },
                        // ]}
                      >
                        <Checkbox>{t("form.languageSkillskurdish")}</Checkbox>
                      </Form.Item>
                    </div>
                  </div>
                </Col>
                {/* <Col xs={24} sm={24} md={8} lg={10} xl={10}>
                  <div className="layout">
                    <p>
                      <b>{t("form.experience")}</b>
                    </p>
                    <Form.Item
                      name="hasExperience"
                      className="dropdown"
                      rules={[
                        {
                          required: true,
                          message: `${t("form.experienceMessage")}`,
                        },
                      ]}
                    >
                      <Radio.Group>
                        <Radio value="yes">{t("form.drivingLicenceYes")}</Radio>
                        <Radio value="no">{t("form.drivingLicenceNo")}</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </div>
                </Col> */}

                <Col xs={24} sm={24} md={4} lg={6} xl={6}>
                  <div className="layout">
                    <p>
                      <b>{t("form.drivingLicence")}</b>
                    </p>
                    <Form.Item
                      name="hasDrivingLicense"
                      className="dropdown"
                      rules={[
                        {
                          required: true,
                          message: `${t("form.drivingLicenceMessage")}`,
                        },
                      ]}
                    >
                      <Radio.Group>
                        <Radio value="yes">{t("form.drivingLicenceYes")}</Radio>
                        <Radio value="no">{t("form.drivingLicenceNo")}</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </div>
                </Col>
              </div>

              <div className="flex">
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <div className="layout">
                    <p>
                      <b>{t("form.vehicleToUse")}</b>
                    </p>
                    <Form.Item
                      onChange={onVehicleChange}
                      name="hasVehicle"
                      className="dropdown"
                      rules={[
                        {
                          required: true,
                          message: `${t("form.vehicleToUseMessage")}`,
                        },
                      ]}
                    >
                      <Radio.Group>
                        <Radio value="yes">{t("form.vehicleToUseYes")}</Radio>
                        <Radio value="no">{t("form.vehicleToUseNo")}</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </div>
                </Col>
              </div>
              <div className="flex">
                {hasVehicleState == "yes" ? (
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <div className="layout">
                      <p>
                        <b>{t("form.VehicleType")}</b>
                      </p>
                      <Form.Item
                        name="VehicleType"
                        className="dropdown"
                        rules={[
                          {
                            required: true,
                            message: `${t("form.VehicleTypeMessage")} `,
                          },
                        ]}
                      >
                        <Radio.Group>
                          <Radio value="car">{t("form.VehicleTypeCar")}</Radio>
                          <Radio value="motor">
                            {t("form.VehicleTypeMotorcycle")}
                          </Radio>
                        </Radio.Group>
                      </Form.Item>
                    </div>
                  </Col>
                ) : null}
                {hasVehicleState == "yes" ? (
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <div className="layout">
                      <p>
                        <b>{t("form.hours")}</b>
                      </p>
                      <Form.Item
                        name="workingHours"
                        className="dropdown"
                        rules={[
                          {
                            required: true,
                            message: `${t("form.hoursMessage")}`,
                          },
                        ]}
                      >
                        <InputNumber
                          min={0}
                          max={20}
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    </div>
                  </Col>
                ) : null}
              </div>
              {/* <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <div className="layout">
                  <p>
                    <b>{t("form.experience")}</b>
                  </p>
                  <Form.Item
                    name="hasExperience"
                    className="dropdown"
                    rules={[
                      {
                        required: true,
                        message: `${t("form.experienceMessage")}`,
                      },
                    ]}
                  >
                    <Radio.Group>
                      <Radio value="yes">{t("form.drivingLicenceYes")}</Radio>
                      <Radio value="no">{t("form.drivingLicenceNo")}</Radio>
                    </Radio.Group>
                  </Form.Item>
                </div>
              </Col> */}

              {/* <div className="flex"> </div> */}
              {/* <Col xs={24} sm={24} md={8} lg={10} xl={10}>
                <div className="layout">
                  <p>
                    <b>{t("form.vaccinated")}</b>
                  </p>
                  <Form.Item
                    name="gotVaccinated"
                    className="dropdown"
                    rules={[
                      {
                        required: true,
                        message: `${t("form.vaccinated")}`,
                      },
                    ]}
                  >
                    <Radio.Group>
                      <Radio value="yes">{t("form.drivingLicenceYes")}</Radio>
                      <Radio value="no">{t("form.drivingLicenceNo")}</Radio>
                    </Radio.Group>
                  </Form.Item>
                </div>
              </Col> */}

              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  style={{ margin: "0px 15px 24px" }}
                >
                  {t("form.submit")}
                </Button>
              </Col>
            </Form>
          </ConfigProvider>
        </div>
      </div>
    </Row>
  );
}

export default App;
